import { ColorPalette, ScreenWidthSettings } from './types';


export const colorPalette: ColorPalette = {
  mitRed: '#A31F34',
  darkGray: '#8A8B8C',
  lightGray: '#C2C0BF',
  lightBlue: '#1976D2',
  yellow: '#CF8624',
  green: '#A8C838',
}

export const screenWidthSettings: ScreenWidthSettings = {
  hideNavButtons: 769,
  searchBarWidth: 320,
  maxScreenWidth: 1920,
  mobileScreenSize: 424,
  tabletScreenSize: 768,
  laptopScreenSize: 1024,
  largeLaptopScreenSize: 1440,
}