import React from 'react';
import { Link } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
} from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import PeopleIcon from '@material-ui/icons/People';
import SearchIcon from '@material-ui/icons/Search';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';

import { State } from '../../types';
import { toggleDrawer } from '../../features/drawerSlice';
import { screenWidthSettings } from '../../constants';

const useStyles = makeStyles({
  list: {
    width: 'auto',
    paddingTop: '75px',
  },
});

const navContents = [
  { icon: <HomeIcon />, text: 'Home' },
  { icon: <PeopleIcon />, text: 'People' },
  { icon: <SearchIcon />, text: 'Research' },
  { icon: <LibraryBooksIcon />, text: 'Publications' },
];

function Drawer() {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(
    theme.breakpoints.up(screenWidthSettings.hideNavButtons)
  );
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentDrawerState = useSelector((state: State) => state.drawerReducer);
  const handleToggleDrawer = (drawerState: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    dispatch(toggleDrawer(drawerState));
  };

  return (
    <SwipeableDrawer
      anchor='right'
      open={currentDrawerState}
      onClose={handleToggleDrawer(false)}
      onOpen={handleToggleDrawer(true)}
      disableBackdropTransition={true}
      disableSwipeToOpen={isLargeScreen ? true : false}
    >
      <div
        className={classes.list}
        role='presentation'
        onClick={handleToggleDrawer(false)}
      >
        <List>
          {navContents.map((item) => {
            let link_text =
              item.text === 'Home' ? `/` : `/${item.text.toLowerCase()}`;
            return (
              <ListItem button key={item.text} component={Link} to={link_text}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItem>
            );
          })}
        </List>
      </div>
    </SwipeableDrawer>
  );
}

export default Drawer;
