import React from 'react';
import { Link } from 'gatsby';

import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { colorPalette, screenWidthSettings } from '../../constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      fontSize: '1rem',
      marginRight: '20px',
      // hide nav buttons when screen width < 769px
      // nav buttons will be replaced with drawer in Drawer.tsx
      [theme.breakpoints.down(screenWidthSettings.hideNavButtons)]: {
        display: 'none',
      },
      '&$focusVisible, &:hover': {
        color: colorPalette.lightBlue,
        transition: 'color 0.5s ease',
      },
    },
    focusVisible: {},
  })
);

const NavButton = ({
  linkText,
  buttonText,
}: {
  linkText: string;
  buttonText: string;
}) => {
  const classes = useStyles();
  return (
    <Button
      color='inherit'
      component={Link}
      to={linkText}
      disableRipple={true}
      classes={{
        root: classes.root,
        focusVisible: classes.focusVisible,
      }}
    >
      {buttonText}
    </Button>
  );
};

export default NavButton;
