import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'gatsby';

import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import { Typography, useMediaQuery } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

import Drawer from './Drawer';
import labLogo from '../../assets/lab_logo.png';
import { State } from '../../types';
import { colorPalette, screenWidthSettings } from '../../constants';
import { toggleDrawer } from '../../features/drawerSlice';
import NavButton from './NavButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      width: '100%',
    },
    navbar: {
      background: 'black',
      zIndex: 1400,
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      overflowX: 'hidden', // hides overflow of child element
    },
    container: {
      width: '100%',
      maxWidth: '1920px',
    },
    logoContainer: {
      textDecoration: 'none',
      userSelect: 'none',
      flexGrow: 1,
      marginLeft: '20px',
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.up(screenWidthSettings.tabletScreenSize)]: {
        height: '75px',
      },
      [theme.breakpoints.down(screenWidthSettings.tabletScreenSize)]: {
        height: '50px',
      },
    },
    logo: {
      height: '70%',
    },
    logoText: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginLeft: '5px',
      color: 'white',
    },
    mainText: {
      color: colorPalette.mitRed,
      fontWeight: 700,
    },
    altText: {
      color: colorPalette.darkGray,
    },
    btnContainer: {
      flexGrow: 0,
      marginRight: '20px',
    },
    menuButton: {
      // hide menu button when screen width >= 769px
      // note: menu swipe-to-open is also disabled for width >= 769px in Drawer.tsx
      [theme.breakpoints.up(screenWidthSettings.hideNavButtons)]: {
        display: 'none',
      },
    },
  })
);

type NavButtonContent = Array<{ link: string; text: string }>;

const navButtonContent: NavButtonContent = [
  { link: `/`, text: 'Home' },
  { link: `/people`, text: 'People' },
  { link: `/research`, text: 'Research' },
  { link: `/publications`, text: 'Publications' },
];

function Navigation() {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobileScreen = useMediaQuery(
    `(max-width:${screenWidthSettings.mobileScreenSize.toString()}px)`
  );

  const currentDrawerState = useSelector((state: State) => state.drawerReducer);

  const dispatch = useDispatch();
  const handleToggleDrawer = (drawerState: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    dispatch(toggleDrawer(drawerState));
  };

  return (
    <div className={classes.root}>
      <AppBar className={classes.navbar} position='relative'>
        <Toolbar className={classes.container}>
          <div className={classes.logoContainer}>
            <Link to='/' className={classes.logo}>
              <img
                alt='biomimetics lab logo'
                src={labLogo}
                style={{ height: '100%' }}
              />
            </Link>
            {!isMobileScreen && (
              <div className={classes.logoText}>
                <Typography
                  variant={isSmallScreen ? 'body1' : 'h6'}
                  component={Link}
                  to='/'
                >
                  <span className={classes.mainText}>MIT </span>
                  <span className={classes.altText}>MECH</span>
                  <span className={classes.mainText}>E</span>
                </Typography>
                <Typography
                  color='inherit'
                  variant={isSmallScreen ? 'body2' : 'body1'}
                  component={Link}
                  to='/'
                >
                  Biomimetic Robotics Lab
                </Typography>
              </div>
            )}
          </div>
          <div className={classes.btnContainer}>
            {navButtonContent.map((item) => (
              <NavButton
                linkText={item.link}
                buttonText={item.text}
                key={item.text}
              />
            ))}
            <IconButton
              onClick={handleToggleDrawer(!currentDrawerState)}
              edge='start'
              className={classes.menuButton}
              color='inherit'
              aria-label='menu'
            >
              {!currentDrawerState && <MenuIcon />}
              {currentDrawerState && <CloseIcon />}
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer />
    </div>
  );
}

export default Navigation;
