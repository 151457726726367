import React from 'react';

import { createStyles, makeStyles } from '@material-ui/core/styles';

import Navigation from './navbar/Navigation';
import './layout.css';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: '#FAFAFA',
      minHeight: '100vh',
      margin: 0,
      padding: 0,
    },
    container: {
      display: 'flex',
      justifyContent: 'center',
    },
  })
);

interface Props {
  children: React.ReactNode;
}

const Layout = (props: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Navigation />
      <div className={classes.container}>{props.children}</div>
    </div>
  );
};

export default Layout;
